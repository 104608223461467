<template>
  <div class="anzhtml m-t-1 pos-r" style="overflow-x: auto">
    <span v-if="data.RenderHtml" v-html="data.RenderHtml"></span>

    <!-- Debug -->
    <DebugItem v-if="debug.visible" :data="data"></DebugItem>
  </div>
</template>

<script>
import DebugItem from '@/components/DebugItem';

export default {
  name: 'ANZHTML',

  components: {
    DebugItem
  },

  props: {
    data: {
      type: Object,
      required: true
    }
  },

  computed: {
    debug() {
      return this.$store.state.app.debug;
    }
  }
};
</script>
